import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const defaultText = 'Choose a design or upload your own!'
const defaultTransform = 'translate(2rem, -8ex) rotate(-11deg)'

export const HandwrittenInputs = [
    {
        name: 'text',
        type: 'string',
        defaultValue: defaultText,
    },
    {
        name: 'transform',
        type: 'string',
        defaultValue: defaultTransform,
    },
]

Builder.registerComponent(
    dynamic(() => import('./HandwrittenText').then((res) => res as any)),
    {
        name: 'Handwritten Text',
        inputs: HandwrittenInputs,
    }
)
