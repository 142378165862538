import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const starColor = '#FFFFFF'
const unfilledStarColor = '#C4C4C4'
const defaultTextColor = '#4B5563'
const defaultRating = 4.5

export const TestimonialInputs = [
    {
        name: 'showImage',
        type: 'boolean',
        defaultValue: true,
        friendlyName: 'Include an image',
    },
    {
        name: 'imageUrl',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue: 'https://placehold.co/281x67',
        friendlyName: 'Image',
    },
    {
        name: 'altText',
        type: 'string',
        defaultValue: 'company logo',
        friendlyName: 'Image alt text',
    },
    {
        name: 'testimonialText',
        type: 'richText',
        defaultValue:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna alised do eiusmod temor incididunt ut labore et dolore magna.',
    },
    {
        name: 'textColor',
        type: 'color',
        defaultValue: defaultTextColor,
    },
    {
        name: 'starRating',
        type: 'number',
        defaultValue: defaultRating,
        friendlyName: '0-5',
    },
    {
        name: 'starColor',
        type: 'color',
        defaultValue: starColor,
    },
    {
        name: 'unfilledStarColor',
        type: 'color',
        defaultValue: unfilledStarColor,
    },
    {
        name: 'starSize',
        type: 'number',
        defaultValue: 30,
    },
]

Builder.registerComponent(
    dynamic(() => import('./Testimonial').then((res) => res as any)),
    {
        name: 'Testimonial',
        inputs: TestimonialInputs,
    }
)
