import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Next Image'

const defaultSrc = 'https://placehold.co/200x200/9A30F5/FFFFFF/png'
const defaultLayout = 'intrinsic'

const NextImageBuilderDefaults = {
    image: defaultSrc,
    layout: defaultLayout,
}

const NextImageBuilderInputs = [
    {
        name: 'image',
        type: 'file',
        bubble: true,
        allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        required: true,
        defaultValue: NextImageBuilderDefaults.image,

        onChange: (options: Map<string, any>) => {
            const DEFAULT_ASPECT_RATIO = 0.7041
            options.delete('srcset')
            options.delete('noWebp')

            function loadImage(
                url: string,
                timeout = 60000
            ): Promise<HTMLImageElement> {
                return new Promise((resolve, reject) => {
                    const img = document.createElement('img')
                    let loaded = false
                    img.onload = () => {
                        loaded = true
                        resolve(img)
                    }

                    img.addEventListener('error', (event) => {
                        reject(event.error)
                    })

                    img.src = url
                    setTimeout(() => {
                        if (!loaded) {
                            reject(new Error('Image load timed out'))
                        }
                    }, timeout)
                })
            }

            function round(num: number) {
                return Math.round(num * 1000) / 1000
            }

            const value = options.get('image')
            const aspectRatio = options.get('aspectRatio')

            // For SVG images - don't render as webp, keep them as SVG
            fetch(value)
                .then((res) => res.blob())
                .then((blob) => {
                    if (blob.type.includes('svg')) {
                        options.set('noWebp', true)
                    }
                })

            if (
                value &&
                (!aspectRatio || aspectRatio === DEFAULT_ASPECT_RATIO)
            ) {
                return loadImage(value).then((img) => {
                    const possiblyUpdatedAspectRatio =
                        options.get('aspectRatio')
                    if (
                        options.get('image') === value &&
                        (!possiblyUpdatedAspectRatio ||
                            possiblyUpdatedAspectRatio === DEFAULT_ASPECT_RATIO)
                    ) {
                        if (img.width && img.height) {
                            options.set(
                                'aspectRatio',
                                round(img.height / img.width)
                            )
                            options.set('height', img.height)
                            options.set('width', img.width)
                        }
                    }
                })
            }
        },
    },
    {
        name: 'layout',
        type: 'string',
        defaultValue: NextImageBuilderDefaults.layout,
        enum: ['fill', 'fixed', 'intrinsic', 'responsive', 'raw'],
    },
    {
        name: 'altText',
        friendlyName: 'Image Alt Text',
        type: 'string',
        defaultValue: '',
    },
]

Builder.registerComponent(
    dynamic(() => import('./NextImage').then((res) => res as any)),
    {
        name: componentName,
        static: true,
        image: NextImageBuilderDefaults.image,
        inputs: NextImageBuilderInputs,
    }
)

export const nextImageBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: NextImageBuilderDefaults,
    },
}
