import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const defaultHeader = 'Lorem ipsum dolor sit amet?'
const defaultText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.'
const defaultAdaChatBot = false

export const FAQInputs = [
    {
        name: 'faqHeader',
        type: 'string',
        defaultValue: defaultHeader,
    },
    {
        name: 'faqText',
        type: 'richText',
        defaultValue: defaultText,
    },
    {
        name: 'includeChatBot',
        type: 'boolean',
        defaultValue: defaultAdaChatBot,
    },
]

Builder.registerComponent(
    dynamic(() => import('./FAQSection').then((res) => res as any)),
    {
        name: 'FAQ Item',
        inputs: FAQInputs,
    }
)
