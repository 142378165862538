import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Headline Eyebrow'
const defaultLabel = '[Eyebrow text]'
const HeadlineEyebrowDefaults = {
    label: defaultLabel,
}
const HeadlineEyebrowBuilderInputs = [
    {
        name: 'label',
        type: 'string',
        defaultValue: HeadlineEyebrowDefaults.label,
    },
]

Builder.registerComponent(
    dynamic(() => import('./HeadlineEyebrow').then((res) => res as any)),
    {
        name: componentName,
        inputs: HeadlineEyebrowBuilderInputs,
    }
)

export const headlineEyebrowBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: HeadlineEyebrowDefaults,
    },
    responsiveStyles: {
        large: {
            fontSize: '20px',
            fontWeight: '500',
            margin: '20px auto 0',
            textAlign: 'center',
        },
    },
}
