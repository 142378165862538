import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Linked Button'

const defaultVariant = 'primary'
const defaultSize = 'md'
const defaultLabel = 'Click Here'
const defaultTextTransform = 'uppercase'
const defaultUrl = '/visa-gift-cards'
const defaultIsExternalUrl = false
const defaultLinkAttributes = {}

const LinkButtonBuilderDefaults = {
    variant: defaultVariant,
    size: defaultSize,
    label: defaultLabel,
    textTransform: defaultTextTransform,
    url: defaultUrl,
    isExternalUrl: defaultIsExternalUrl,
    linkAttributes: defaultLinkAttributes,
}

export const LinkButtonBuilderInputs = [
    {
        name: 'variant',
        type: 'string',
        defaultValue: defaultVariant,
        enum: [
            'primary',
            'primaryOutline',
            'secondary',
            'secondaryOutline',
            'whiteOutlineTransparent',
        ],
    },
    {
        name: 'size',
        type: 'string',
        defaultValue: defaultSize,
        enum: ['sm', 'md', 'lg'],
    },
    {
        name: 'label',
        type: 'string',
        defaultValue: defaultLabel,
    },
    {
        name: 'buttonWidth',
        type: 'string',
    },
    {
        name: 'textTransform',
        type: 'string',
        defaultValue: defaultTextTransform,
        enum: ['uppercase', 'lowercase', 'none'],
    },
    {
        name: 'url',
        type: 'string',
        defaultValue: defaultUrl,
    },
    {
        name: 'isExternalUrl',
        type: 'boolean',
        defaultValue: defaultIsExternalUrl,
    },
    {
        name: 'linkAttributes',
        type: 'map',
        defaultValue: defaultLinkAttributes,
        helperText: 'e.g. id, target, rel',
        friendlyName: 'Link HTML attributes',
    },
]

Builder.registerComponent(
    dynamic(import('./LinkButton').then((res) => res as any)),
    {
        name: componentName,
        inputs: LinkButtonBuilderInputs,
    }
)

export const linkButtonBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: LinkButtonBuilderDefaults,
    },
    responsiveStyles: {
        large: {
            margin: '20px auto',
        },
    },
}
