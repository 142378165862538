import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Multicolor Text'
const defaultStartText = 'Some'
const defaultColorText = 'colored'
const defaultEndText = 'text'
const defaultHtmlElement = 'span'

const defaultSecondaryColor = {
    solidHex: '#9A30F5',
    useGradient: false,
    gradient: {
        rotation: 44.53,
        firstColor: '#9A30F5',
        firstColorPercent: 11.47,
        secondColor: '#3D7CF6',
        secondColorPercent: 89.19,
    },
}

const MulticolorTextBuilderDefaults = {
    startText: defaultStartText,
    colorText: defaultColorText,
    endText: defaultEndText,
    secondaryColor: defaultSecondaryColor,
    htmlElement: defaultHtmlElement,
}

const MulticolorTextBuilderInputs = [
    {
        name: 'htmlElement',
        type: 'string',
        defaultValue: 'span',
        enum: ['p', 'a', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
        name: 'fontSizeType',
        type: 'string',
        defaultValue: 'span',
        enum: ['auto', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
        name: 'startText',
        type: 'string',
        defaultValue: defaultStartText,
    },
    {
        name: 'colorText',
        type: 'string',
        defaultValue: defaultColorText,
    },
    {
        name: 'endText',
        type: 'string',
        defaultValue: defaultEndText,
    },
    {
        name: 'weight',
        type: 'string',
        enum: ['bold', 'inherit'],
        defaultValue: 'inherit',
    },
    {
        name: 'secondaryColor',
        type: 'object',
        defaultValue: defaultSecondaryColor,
        subFields: [
            {
                name: 'solidHex',
                type: 'color',
                defaultValue: defaultSecondaryColor.solidHex,
            },
            {
                name: 'useGradient',
                type: 'boolean',
                defaultValue: defaultSecondaryColor.useGradient,
            },
            {
                name: 'gradient',
                type: 'object',
                defaultValue: defaultSecondaryColor.gradient,
                subFields: [
                    {
                        name: 'rotation',
                        type: 'number',
                        defaultValue: defaultSecondaryColor.gradient.rotation,
                    },
                    {
                        name: 'firstColor',
                        type: 'color',
                        defaultValue: defaultSecondaryColor.gradient.firstColor,
                    },
                    {
                        name: 'firstColorPercent',
                        type: 'number',
                        defaultValue:
                            defaultSecondaryColor.gradient.firstColorPercent,
                    },
                    {
                        name: 'secondColor',
                        type: 'color',
                        defaultValue:
                            defaultSecondaryColor.gradient.secondColor,
                    },
                    {
                        name: 'secondColorPercent',
                        type: 'number',
                        defaultValue:
                            defaultSecondaryColor.gradient.secondColorPercent,
                    },
                ],
            },
        ],
    },
]

Builder.registerComponent(
    dynamic(() => import('./MulticolorText').then((res) => res as any)),
    {
        name: componentName,
        defaultStyles: {
            fontSize: '40px',
            fontWeight: '500',
            margin: '0 auto',
            maxWidth: '80%',
            textAlign: 'center',
        },
        inputs: MulticolorTextBuilderInputs,
    }
)

export const multicolorTextBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: MulticolorTextBuilderDefaults,
    },
    responsiveStyles: {
        large: {
            fontSize: '40px',
            fontWeight: '500',
            margin: '0 auto',
            maxWidth: '80%',
            textAlign: 'center',
        },
    },
}
