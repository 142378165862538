import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

export const PostItNoteInputs = [
    {
        name: 'backgroundImage',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F3d07abc16a68425bb9073efc280d0e67',
    },
    {
        name: 'postItText',
        type: 'longText',
        defaultValue:
            'Thank you for being so kind! Enjoy a cup of coffee on me.',
    },
    {
        name: 'nameText',
        type: 'string',
        defaultValue: 'Bill Martin',
    },
    {
        name: 'personImage',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue:
            'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
        friendlyName: 'Image of Person',
    },
]

Builder.registerComponent(
    dynamic(() => import('./PostItNote').then((res) => res as any)),
    {
        name: 'Post-It Note',
        inputs: PostItNoteInputs,
    }
)
