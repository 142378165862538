import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const defaultVariant = 'primary'
const defaultSize = 'md'
const defaultLabel = 'Click Here'
const defaultTextTransform = 'uppercase'
const defaultWidth = 'fitContent'

export const ButtonBuilderInputs = [
    {
        name: 'label',
        type: 'string',
        defaultValue: defaultLabel,
    },
    {
        name: 'showArrow',
        type: 'boolean',
        defaultValue: false,
    },
    {
        name: 'variant',
        type: 'string',
        defaultValue: defaultVariant,
        enum: [
            'primary',
            'primaryOutline',
            'secondary',
            'secondaryOutline',
            'deactivated',
            'whiteOutlineTransparent',
        ],
    },
    {
        name: 'size',
        type: 'string',
        defaultValue: defaultSize,
        enum: ['sm', 'md', 'lg'],
    },
    {
        name: 'width',
        type: 'string',
        defaultValue: defaultWidth,
        enum: ['standard', 'fitContent', 'full', 'wide'],
    },
    {
        name: 'textTransform',
        type: 'string',
        defaultValue: defaultTextTransform,
        enum: ['uppercase', 'lowercase', 'none'],
    },
]

Builder.registerComponent(
    dynamic(import('./Button').then((res) => res as any)),
    {
        name: 'Standard Button',
        inputs: ButtonBuilderInputs,
    }
)
