import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

export const ProductCardInputs = [
    {
        name: 'productImage',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue:
            'https://images.unsplash.com/photo-1592903297149-37fb25202dfa?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715',
        required: true,
        friendlyName: 'Product Image',
    },
    {
        name: 'altText',
        type: 'string',
        friendlyName: 'Image alt text',
    },
    {
        name: 'imagePosition',
        type: 'string',
        defaultValue: 'center',
        enum: ['bottom', 'top', 'center', 'left', 'right'],
    },
    {
        name: 'productName',
        type: 'string',
        defaultValue: 'The PerfectGift',
        required: true,
        friendlyName: 'Product Name',
    },
    {
        name: 'productDescription',
        type: 'longText',
        defaultValue: 'You customize. we deliver. they choose.',
        friendlyName: 'Product Description',
    },
    {
        name: 'productUrl',
        type: 'string',
        friendlyName: 'Product URL',
    },
    {
        name: 'showButton',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show button',
    },
    {
        name: 'buttonText',
        type: 'string',
        defaultValue: 'Buy now',
        friendlyName: 'Button text',
    },
    {
        name: 'buttonVariant',
        type: 'string',
        defaultValue: 'primaryOutline',
        enum: ['primary', 'primaryOutline', 'secondary'],
    },
    {
        name: 'newProduct',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Show callout',
    },
    {
        name: 'calloutText',
        type: 'string',
        defaultValue: 'New!',
    },
    {
        name: 'calloutTextSize',
        type: 'string',
        defaultValue: '80%',
    },
    {
        name: 'starColor',
        type: 'color',
        defaultValue: '#4B5563',
    },
    {
        name: 'unfilledStarColor',
        type: 'color',
        defaultValue: '#C4C4C4',
    },
    {
        name: 'starRating',
        type: 'number',
        friendlyName: 'Star rating (0 - 5)',
    },
    {
        name: 'starSize',
        type: 'number',
        defaultValue: 30,
    },
    {
        name: 'totalReviews',
        type: 'number',
    },
]

Builder.registerComponent(
    dynamic(() => import('./ProductCard').then((res) => res as any)),
    {
        name: 'Product Card',
        inputs: ProductCardInputs,
    }
)
