import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Google Reviews'

Builder.registerComponent(
    dynamic(() => import('./GoogleReview').then((res) => res as any)),
    {
        name: componentName,
    }
)

