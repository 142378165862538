import { Builder, BuilderElement } from '@builder.io/react'
import { multicolorTextBuilderElement } from '../text/MulticolorText.builder'
import { headlineEyebrowBuilderElement } from '../headlines/HeadlineEyebrow.builder'
import { linkButtonBuilderElement } from '../buttons/LinkButton.builder'
import dynamic from 'next/dynamic'
import { nextImageBuilderElement } from '../images/NextImage.builder'

const defaultSubtitleText: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: 'Text',
        options: {
            text: 'This is subtitle text',
        },
    },
    responsiveStyles: {
        large: {
            fontSize: '20px',
            fontWeight: '500',
            margin: '0 auto',
            maxWidth: '80%',
            textAlign: 'center',
        },
    },
}

const ActionCardBuilderInputs = [
    {
        name: 'showImage',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'showTitleEyebrow',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'showSubtitle',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'showButton',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'imageComponent',
        type: 'uiBlocks',
        defaultValue: [nextImageBuilderElement],
    },
    {
        name: 'headlineEyebrowComponent',
        type: 'uiBlocks',
        defaultValue: [headlineEyebrowBuilderElement],
    },
    {
        name: 'multicolorTextComponent',
        type: 'uiBlocks',
        defaultValue: [multicolorTextBuilderElement],
    },
    {
        name: 'subtitleTextComponent',
        type: 'uiBlocks',
        defaultValue: [defaultSubtitleText],
    },
    {
        name: 'linkButtonComponent',
        type: 'uiBlocks',
        defaultValue: [linkButtonBuilderElement],
    },
]

Builder.registerComponent(
    dynamic(() => import('./ActionCard').then((res) => res as any)),
    {
        name: 'Action Card',
        canHaveChildren: true,
        inputs: ActionCardBuilderInputs,
        defaultStyles: {
            maxWidth: '350px',
            backgroundColor: '#F0EBEB',
        },
    }
)
