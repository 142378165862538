import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const componentName = 'Star Rating'
const defaultRatingValue = 4.5
const defaultFillColor = '#FFFFFF'
const defaultEmptyColor = '#4B5563'
const defaultStarSize = 30

const StarRatingBuilderDefaults = {
    ratingValue: defaultRatingValue,
    fillColor: defaultFillColor,
    emptyColor: defaultEmptyColor,
    starSize: defaultStarSize,
}

const StarRatingBuilderInputs = [
    {
        name: 'ratingValue',
        type: 'number',
        defaultValue: StarRatingBuilderDefaults.ratingValue,
        friendlyName: '0-5',
    },
    {
        name: 'fillColor',
        type: 'color',
        defaultValue: StarRatingBuilderDefaults.fillColor,
    },
    {
        name: 'emptyColor',
        type: 'color',
        defaultValue: StarRatingBuilderDefaults.emptyColor,
    },
    {
        name: 'starSize',
        type: 'number',
        defaultValue: StarRatingBuilderDefaults.starSize,
    },
]

Builder.registerComponent(
    dynamic(() => import('./StarRating').then((res) => res as any)),
    {
        name: componentName,
        inputs: StarRatingBuilderInputs,
    }
)

export const starRatingBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: StarRatingBuilderDefaults,
    },
}
