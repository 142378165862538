import { Builder, BuilderElement } from '@builder.io/react'
import dynamic from 'next/dynamic'

const purple = '$primary'
const blue = '$secondary'
const gray = '$mediumGray'
const black = '$darkest'
const componentName = 'Press Coverage'

const imageUrlDefault = 'https://placehold.co/226x44'
const pressUrlDefault = 'https://perfectgift-consumer-telos-gifting.vercel.app/'
const showImageDefault = false
const showIconDefault = false
const pressTitleDefault = '24 Best Gifts this Holiday Season'
const pressTitleColorDefault = purple
const pressDateDefault = 'December 1, 2021'
const pressDateColorDefault = black

const PressCoverageDefaults = {
    imageUrl: imageUrlDefault,
    showImage: showImageDefault,
    showIcon: showIconDefault,
    pressUrl: pressUrlDefault,
    pressDate: pressDateDefault,
    pressDateColor: pressDateColorDefault,
    pressTitle: pressTitleDefault,
    pressTitleColor: pressTitleColorDefault,
}

export const PressCoverageInputs = [
    {
        name: 'imageUrl',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue: imageUrlDefault,
        friendlyName: 'Press Image',
    },
    {
        name: 'showImage',
        type: 'boolean',
        defaultValue: showImageDefault,
    },
    {
        name: 'showIcon',
        type: 'boolean',
        defaultValue: showIconDefault,
    },
    {
        name: 'pressUrl',
        type: 'string',
        defaultValue: pressUrlDefault,
    },
    {
        name: 'pressDate',
        type: 'string',
        defaultValue: pressDateDefault,
    },
    {
        name: 'pressDateColor',
        type: 'string',
        defaultValue: pressDateColorDefault,
        enum: [black, gray],
    },
    {
        name: 'pressTitle',
        type: 'string',
        defaultValue: pressTitleDefault,
    },
    {
        name: 'pressTitleColor',
        type: 'string',
        defaultValue: pressTitleColorDefault,
        enum: [purple, blue],
    },
]

Builder.registerComponent(
    dynamic(() => import('./PressCoverage').then((res) => res as any)),
    {
        name: componentName,
        inputs: PressCoverageInputs,
        noWrap: true, // Removes linkUrl prop https://forum.builder.io/t/disable-link-url-data-field-for-a-custom-component/3172
    }
)

Builder.registerComponent(
    dynamic(() => import('./PressCoverage').then((res) => res as any)),
    {
        name: 'Press Coverage 2',
        inputs: PressCoverageInputs,
        noWrap: true, // Removes linkUrl prop https://forum.builder.io/t/disable-link-url-data-field-for-a-custom-component/3172
    }
)

export const pressCoverageBuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: componentName,
        options: PressCoverageDefaults,
    },
}

export const pressCoverage2BuilderElement: BuilderElement = {
    '@type': '@builder.io/sdk:Element',
    component: {
        name: 'Press Coverage 2',
        options: PressCoverageDefaults,
    },
}
