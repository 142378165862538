import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const ModalInputs = [
    {
        name: 'modalName',
        type: 'reference',
        model: 'modal-content',
    },
    {
        name: 'imageAltText',
        type: 'string',
    },
]

Builder.registerComponent(
    dynamic(() => import('./ModalTrigger').then((res) => res as any)),
    {
        name: 'Modal Trigger: Modal Trigger',
        canHaveChildren: true,
        inputs: ModalInputs,
        // Adding defaults is important for easy usability
        defaultChildren: [
            {
                '@type': '@builder.io/sdk:Element',
                component: {
                    name: 'Standard Button',
                    options: {
                        label: 'Coming soon!',
                        width: 'standard',
                        textTransform: 'uppercase',
                        variant: 'deactivated',
                        size: 'md',
                    },
                },
            },
        ],
    }
)
